<!--  设置公司展示 基础信息  -->
<template>
  <div class>
    <div class="position_btn">
      <el-button class="btn" @click="submit">
        <span ref="submit">保存</span>
      </el-button>
      <!-- <el-button v-if="isForm" class="btn" @click="exit">取消</el-button> -->
    </div>

    <div class="form" v-loading="loading">
      <el-form
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
        :model="formData"
      >
        <el-form-item label="公司名称">
          <el-input
            v-model.trim="formData.company_name"
            placeholder="请认真填写，保存后不可更改"
            :disabled="is_disabled"
          ></el-input>
          <!-- <span v-else>{{formData.company_name}}</span> -->
        </el-form-item>
        <el-form-item label="公司规模">
          <!-- <el-select placeholder="不限" v-model="formData.company_name">
            <el-option
              v-for="(item, index) in company_scale"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>-->
          <el-input
            class="smallIpt"
            v-model.number="formData.company_people_min"
          ></el-input>
          <!-- <span v-else>{{formData.company_people_min}}</span> -->
          <span style="padding:0 10px">-</span>
          <el-input
            class="smallIpt"
            v-model.number="formData.company_people_max"
          ></el-input>

          <!-- <span v-else>{{formData.company_people_max}}</span> -->
          <span style="padding-left:10px">人</span>
        </el-form-item>

        <el-form-item label="公司所在地区">
          <el-cascader
            :options="cn"
            v-model="arrCnid"
            @change="handleChangeCN"
          ></el-cascader>
          <!-- <span v-else>{{company_cn_name}}</span> -->
        </el-form-item>
        <el-form-item label="公司行业">
          <el-cascader
            :options="industry"
            :props="{ value: 'industry_id', label: 'name' }"
            v-model="formData.company_industry_id"
            @change="handleChange"
          ></el-cascader>
          <!-- <span v-else>{{company_industry_name}}</span> -->
        </el-form-item>
        <el-form-item label="公司简介">
          <el-input
            type="textarea"
            class="area"
            v-model="formData.company_introduce"
            show-word-limit
            maxlength="300"
          ></el-input>
          <!-- <div v-html="formData.company_introduce" v-else style="max-height:100px;overflow:hidden"></div> -->
          <!-- <el-button class="btn" @click="centerDialogVisible= true">编辑</el-button>
          <div v-html="editorContent" class="introduce"></div>-->
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model.trim="formData.company_map_detail"></el-input>
          <!-- <span v-else>{{formData.company_map_detail}}</span> -->
        </el-form-item>
        <el-form-item label="LOGO">
          <!-- <span class="warn-text">最多上传一张照片</span> -->
          <el-upload
            :class="{ disable: imagesMax }"
            :on-success="successFile"
            :on-remove="OnremoveHandler"
            action="/api/kapin_ee/user/v1/files_upload/"
            list-type="picture-card"
            :file-list="fileList"
            :before-remove="removeHandler"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <div class="img-wrap" v-else>
            <img :src="imgUrl" alt />
          </div>-->

          <el-dialog :visible.sync="dialogVisibleImg">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </el-form-item>
      </el-form>
      <!-- <template v-else>
        <div v-loading="loading" class="only-show">
          <div class="show_title">公司名称</div>
          <p class="show_content">{{formData.company_name?formData.company_name:'未填写'}}</p>
          <div class="show_title">公司规模</div>
          <p class="show_content">
            {{formData.company_people_min}}
            <span>-</span>
            {{formData.company_people_max}}
          </p>
          <div class="show_title">公司行业</div>
          <p class="show_content">{{company_industry_name?company_industry_name:'未填写'}}</p>
          <div class="show_title">公司简介</div>
        <p
          class="show_content"
          >{{formData.company_industry_name?formData.company_industry_name:'未填写'}}</p>
          <div class="show_title">公司地址</div>
          <p class="show_content">{{formData.company_map_detail?formData.company_map_detail:'未填写'}}</p>
        </div>
        <div class="show_title">公司地址</div>
        <p
          class="show_content"
        >{{formData.company_industry_name?formData.company_industry_name:'未填写'}}</p>
      </template>-->
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { company_scale } from '@/utils/searchtype.js'
import { setShowApi, getLocation, showCity } from '@/api/me'
import axios from 'axios'
import { mapActions } from 'vuex'
// import { getCompanyShow } from '@/api/company/company.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      formData: {
        company_name: '',
        company_people_min: '',
        company_people_max: '',
        company_introduce: '',
        company_logo: '',
        company_map_detail: '',

        company_industry_id: '',
      },
      fileList: [],
      company_scale: [],
      //图片路径
      dialogImageUrl: '',
      //是否显示el-dialog
      dialogVisibleImg: false,
      // 图片上传列表
      // file_list: [],
      //图片最多yi张
      imagesMax: false,
      isForm: false,
      //行业
      industry: [],
      //行业字符串
      company_industry_name: '',
      company_cn_name: '',
      imgUrl: '',
      //省市区
      cn: [],
      arrCnid: [],
      is_disabled: false,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ...mapActions({
      getCompanyShowData: 'company/getCompanyShowData',
    }),
    submit() {
      this.isForm = true
      this.valid()
    },
    //验证
    valid() {
      console.log(this.formData)
      // let arr = [
      //   'company_name',
      //   'company_people_min',
      //   'company_people_max',
      //   'company_introduce',
      //   'company_logo',
      //   'company_map_detail'
      // ]
      if (this.$refs.submit.textContent === '保存') {
        let info = this.formData
        for (let prop in info) {
          // console.log(prop)
          //验证是否都填写了
          if (info[prop] === '') {
            this.$message({
              message: '请完善公司信息',
              type: 'warning',
            })
            return
          }
          //人数规模
          if (prop === 'company_people_min' || prop === 'company_people_max') {
            // console.log(info[prop])
            if (typeof info[prop] != 'number') {
              // console.log('222')
              this.$message({
                message: '请输入正确的公司规模',
                type: 'warning',
              })
              return
            }
          }
        }
        if (info.company_people_min >= info.company_people_max) {
          this.$message({
            message: '请输入正确的公司规模',
            type: 'warning',
          })
          return
        }
        this.formData.company_introduce = this.formData.company_introduce.replace(
          /\n/g,
          '\n'
        )
        if (info.company_map_detail) {
          //获取经纬度
          this.getCoordinate(info.company_map_detail)
        }
      }
    },
    //所在地
    handleChangeCN(value) {
        console.log("ddddddddd") 
      console.log(value) 

      // this.formData.province_id = value[0] 
      // this.formData.city_id = value[1]
      // this.formData.district_id = value[2]

      this.formData.company_place_id = value[value.length - 1]
 

       
    },
    //行业
    handleChange(value) {
      console.log(value)
      this.formData.company_industry_id = value[value.length - 1]
      console.log(this.formData.company_industry_id)
    },
    // 经纬度
    getCoordinate(position) {
      getLocation({ type: '1', message: position }).then((res) => {
        if (res.data.status === 0) {
          // this.formData.company_place_id = res.result.ad_info.adcode
          this.formData.company_map_title = this.formData.company_name
          this.formData.company_place_latitude = res.data.result.location.lat
          this.formData.company_place_longitude = res.data.result.location.lng
          this.setApi()
        } else {
          this.$message({
            message: '请输入正确的地址',
            type: 'warning',
          })
        }
      })

      // axios
      //   .get(
      //     '/direction/ws/geocoder/v1/?address=?' +
      //       position +
      //       '&key=' +
      //       'TGVBZ-PSPKO-KEEWS-SC337-WK5A3-CIFX2'
      //   )
      //   .then(res => {
      //     console.log(res)
      //     if (res.status === 0) {
      //       // this.formData.company_place_id = res.result.ad_info.adcode
      //       this.formData.company_map_title = res.result.title
      //       this.formData.company_place_latitude = res.result.location.lat
      //       this.formData.company_place_longitude = res.result.location.lng
      //       this.setApi()
      //     } else {
      //       this.$message({
      //         message: '请输入正确的地址',
      //         type: 'warning'
      //       })
      //     }
      //   })
    },
    //api设置公司展示
    setApi() {
      console.log(this.formData)
      

      if (Array.isArray(this.formData.company_industry_id)) {
        this.formData.company_industry_id = this.formData.company_industry_id[
          this.formData.company_industry_id.length - 1
        ]
      }
      // return
      // let update_data = { update_data: this.formData }
      setShowApi(this.formData).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: '保存成功',
            type: 'success',
          })
          this.is_disabled = true
          this.getCompanyShowData({
            type: 'detail',
            succeed: (res) => {
              // console.log(res)
              this.fileList = []
              // this.isForm = false
              this.imgUrl = res.data.company_logo
              this.fileList.push({
                url: res.data.company_logo,
              })
              if (this.fileList.length >= 1) {
                this.imagesMax = true
              } else {
                this.imagesMax = false
              }
              this.formData.company_name = res.data.company_name

              this.formData.company_people_min = res.data.company_people_min
              this.formData.company_people_max = res.data.company_people_max
              this.formData.company_introduce = res.data.company_introduce
              this.formData.company_logo = res.data.company_logo_file_id
              this.formData.company_map_detail = res.data.company_map_detail
              this.formData.company_industry_id = [
                Number(res.data.company_industry_data.first_industry_id),
                Number(res.data.company_industry_data.second_industry_id),
              ]
              this.company_industry_name =
                res.data.company_industry_data.first_industry_name +
                res.data.company_industry_data.second_industry_name
              this.company_cn_name =
                res.data.company_place_data.province_name +
                res.data.company_place_data.city_name +
                res.data.company_place_data.district_name
            },
          })
          console.log(this)
        } else {
          this.$message({
            message: res.msg,
            type: 'error',
          })
        }
      })
    },
    //点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisibleImg = true
      // console.log(file)
    },
    //删除图片前的确认框
    removeHandler(file, fileList) {
      return this.$confirm('是否确定删除此图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch((e) => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
          return Promise.reject()
        })
    },
    //删除图片
    OnremoveHandler(file, fileList) {
      this.formData.company_logo = ''
      if (fileList.length >= 1) {
        this.imagesMax = true
      } else {
        this.imagesMax = false
      }
    },
    //上传图片
    successFile(response, file, fileList) {
      this.formData.company_logo = response.file_id
      console.log(response, file, fileList)
      if (fileList.length >= 1) {
        this.imagesMax = true
      } else {
        this.imagesMax = false
      }
    },
    //退出
    exit() {
      console.log(1)
      this.isForm = false
      // this.getInfo()
    },
    getInfo() {
      this.getCompanyShowData({
        type: 'detail',
        succeed: (res) => {
          // console.log(res)
          if (res.data.company_logo != '') {
            this.imgUrl = res.data.company_logo
            this.fileList.push({
              url: res.data.company_logo,
            })
          }

          if (this.fileList.length >= 1) {
            this.imagesMax = true
          } else {
            this.imagesMax = false
          }
          this.formData.company_name = res.data.company_name
          if (this.formData.company_name.length > 0) {
            this.is_disabled = true
          } else {
            this.is_disabled = false
          }

          this.formData.company_people_min = res.data.company_people_min
          this.formData.company_people_max = res.data.company_people_max
          this.formData.company_introduce = res.data.company_introduce
          this.formData.company_logo = res.data.company_logo_file_id
          this.formData.company_map_detail = res.data.company_map_detail
          if (
            res.data.company_industry_data.second_industry_name != undefined
          ) {
            this.company_industry_name =
              res.data.company_industry_data.first_industry_name +
              res.data.company_industry_data.second_industry_name
          }

          if (
            res.data.company_place_data
            // &&res.data.company_place_data.province_name != undefined
          ) {
            this.company_cn_name =
              res.data.company_place_data.province_name +
              res.data.company_place_data.city_name +
              res.data.company_place_data.district_name
          }

          this.formData.company_industry_id = [
            Number(res.data.company_industry_data.first_industry_id),
            Number(res.data.company_industry_data.second_industry_id),
          ]

          if (res.data.company_place_data.province_code === undefined) {
            console.log(1)
            this.arrCnid = [
               
              res.data.company_place_data.city_code,
              res.data.company_place_data.district_code,
            ]
          } else {
            this.arrCnid = [
              res.data.company_place_data.province_code,
              res.data.company_place_data.city_code,
              res.data.company_place_data.district_code,
            ]
          }
 

          console.log(this.formData, '公司所在地区')

          this.loading = false
        },
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log('进入基本信息1')
    showCity({}).then((res) => {
      this.cn = res.data
      console.log(res, this.cn, 'city')
    })
    this.company_scale = company_scale
    axios.get('/static/industry.json').then((res) => {
      console.log(res)
      this.industry = res
    })
    // axios.get("/static/cn.json").then((res) => {
    //   this.cn = res;
    // });

    this.getInfo()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log('进入基本信息2')
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.smallIpt {
  width: 70px !important;
}
.area /deep/ .el-textarea__inner {
  width: 90%;
  min-height: 200px !important;
  font-family: auto;
}
// .warn-text {
//   font-size: 12px;
//   color: red;
// }
.disable /deep/ .el-upload--picture-card {
  display: none;
}
//只看
.only-show {
  padding-left: 20px;
}
.img-wrap {
  width: 150px;
  height: 150px;
}
.img-wrap img {
  width: 100%;
  height: 100%;
}
div /deep/ .el-form-item__label::before {
  content: '*';
  /* position: absolute; */
  padding-right: 6px;
  color: $main5_color;
}
</style>
