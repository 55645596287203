<!-- 设置公司展示 -->
<template>
  <div class="wrap">
    <!-- <div class="welcome">
      <p>请完善您的公司相关信息</p>
      <p>你现已完善6/7</p>
    </div>-->
    <el-tabs tab-position="left" v-model="active" @tab-click="tabClickHandler">
      <el-tab-pane label="第一步：公司基本信息" name="base">
        <baseInformationComponent></baseInformationComponent>
      </el-tab-pane>
      <el-tab-pane label="第二步：风云人物信息" name="hot">
        <hotManComponent></hotManComponent>
      </el-tab-pane>
      <el-tab-pane label="第三步：公司相册管理" name="images">
        <teamShowComponent></teamShowComponent>
      </el-tab-pane>
    </el-tabs>
    <!-- <ul class="smk-float">
      <li
        @click="goPage('/setshow/baseInformation')"
        :class="{isPath:$route.path==='/setshow/baseInformation'}"
      >公司基本信息</li>
      <li
        :class="{isPath:$route.path === '/setshow/hotMan'}"
        @click="goPage('/setshow/hotMan') "
      >风云人物</li>
      <li
        :class="{isPath:$route.path==='/setshow/teamimg'}"
        @click="goPage('/setshow/teamimg')"
      >公司相册管理</li>
    </ul>-->
    <!-- <div class="set-info">
      <router-view></router-view>
    </div>-->
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import baseInformationComponent from '@/components/setShow/baseInformation'
import hotManComponent from '@/components/setShow/hotMan'
import teamShowComponent from '@/components/setShow/teamShow'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    baseInformationComponent,
    hotManComponent,
    teamShowComponent
  },
  data() {
    //这里存放数据
    return {
      active: '',
      //
      isPath: '/setshow/baseInformation'
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    tabClickHandler(tab) {
      if (this.$route.query.type != tab.name) {
        this.$router.replace({ path: '/setshow', query: { type: tab.name } })
      }
    },

    goPage(path) {
      this.$router.replace(path)
      this.isPath = path
      console.log(path)
      console.log(this.$route.path)
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.active = this.$route.query.type || 'base'
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style  scoped lang="scss" >
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
}
div /deep/ .el-input {
  width: 300px;
}
div /deep/ .form {
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: calc(90vh - 150px);
  background-color: #fff;
  box-shadow: $box-shadow;
  border-radius: $radius;
}
div /deep/ .el-textarea__inner {
  width: 300px;
}
div /deep/ .el-tabs__active-bar {
  background-color: $main_color;
}
div /deep/ .el-tabs__item:hover {
  color: $main_color;
}
div /deep/ .el-tabs__item.is-active {
  color: $main_color;
  background-color: #e4e7ed;
}
div /deep/ .el-tabs--left .el-tabs__header.is-left {
  padding-top: 50px;
  // padding-right: 50px;
  width: 200px;
  background-color: #fff;
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: calc(90vh - 150px);
}
.welcome {
  position: relative;
  top: 0px;
  left: 0;
  z-index: 0;
  width: 200px;
  height: 0;
}
div /deep/ .show_content {
  margin: 10px 0;
  margin-left: 30px;
  padding: 0;
  text-align: center;
}
div /deep/ .show_title {
  // padding-left: 50px;
  // margin-top: 30px;
  color: #333;
  font-size: 16px;
  &::after {
    content: '';
    width: 20px;
    display: block;
    border: 1px solid $main_color;
    margin-top: 7px;
  }
}
//
.set-info {
  position: relative;
  float: left;
  width: 90%;
}
.smk-float {
  float: left;
  text-align: right;
  margin-right: 10px;
  padding-right: 3px;
}
.smk-float li {
  margin-top: 10px;
}
.smk-float .isPath {
  color: #ff9900;
}
</style>