<!-- 风云人物 -->
<template>
  <div class v-loading="loading">
    <div class="position_btn">
      <el-button class="btn" @click="submit">
        <span ref="submit">保存</span>
      </el-button>
      <!-- <el-button v-if="isForm" class="btn" @click="isForm = false">取&emsp;消</el-button> -->
    </div>
    <div class="form">
      <el-form ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-form-item label="风云人物姓名">
          <el-input v-model="formData.sign_name"></el-input>
          <!-- <span v-else>{{formData.sign_name}}</span> -->
        </el-form-item>
        <el-form-item label="风云人物职位">
          <el-input v-model="formData.sign_position"></el-input>
          <!-- <span v-else>{{formData.sign_position}}</span> -->
        </el-form-item>
        <el-form-item label="风云人物介绍">
          <el-input type="textarea" class="area" v-model="formData.sign_introduce"></el-input>
          <!-- <div v-html="formData.sign_introduce" v-else></div> -->
        </el-form-item>
        <el-form-item label="风云人物照片">
          <el-upload
            :class="{disable:imagesMax}"
            action="/api/kapin_ee/user/v1/files_upload/"
            :file-list="fileList"
            list-type="picture-card"
            :before-remove="removeHandler"
            :on-remove="OnremoveHandler"
            :on-success="successFile"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <div class="img-wrap" v-else>
            <img :src="imgUrl" alt />
          </div>-->

          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </el-form-item>
      </el-form>
      <!-- <template v-else>
        <div class="img-wrap" v-for="(item, index) in formDataList" :key="index">
          <div class="img-box">
            <img :src="item.sign_img" alt title="风云人物" />
          </div>
          <div>
            <p class="post-info">{{item.sign_name}}</p>
          </div>
          <p class="post-info">{{item.sign_position}}</p>
          <p class="handle">
            <span @click="changeHandler(index)">修改</span>
            <span @click="deleteHandler(index)">删除</span>
          </p>
        </div>
        <div class="post-info" v-html="companyData.sign_introduce"></div>
      </template>-->
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { setSignApi } from '@/api/me'
import { mapActions } from 'vuex'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      //图片路径
      dialogImageUrl: '',
      //是否显示el-dialog
      dialogVisible: false,
      editorContent: '',
      editor: null,
      // 介绍
      centerDialogVisible: false,
      count: 0,
      formData: {
        sign_name: '',
        sign_position: '',
        sign_introduce: '',
        sign_img: ''
      },
      imagesMax: false,
      //操作功能是否显示
      handleIndex: 0,
      formDataList: [],
      fileList: [],
      isForm: false,
      // isChange: false,
      //图片
      imgUrl: ''
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    ...mapActions({
      getCompanyShowData: 'company/getCompanyShowData'
    }),
    //点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //图片上传
    successFile(response, file, fileList) {
      console.log(response, file, fileList)
      this.formData.sign_img = response.file_id
      if (fileList.length >= 1) {
        this.imagesMax = true
      } else {
        this.imagesMax = false
      }
    },
    //图片删除确认框
    removeHandler(file, fileList) {
      return this.$confirm('确定删除此图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(e => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
          return Promise.reject()
        })
    },
    //图片删除
    OnremoveHandler(file, fileList) {
      this.formData.file_id = ''
      this.formData.sign_img = ''
      if (fileList.length > 0) {
        this.imagesMax = true
      } else {
        this.imagesMax = false
      }
    },
    /*
添加一个风云人物  重置表单与图片上传数组  
表单上传  按钮上传  另一个 isChange 判断是修改过来的还是添加过来的
*/
    submit() {
      // if (this.$refs.submit.textContent === '添加') {
      //   this.formData = {
      //     sign_name: '',
      //     sign_position: '',
      //     sign_introduce: '',
      //     sign_img: ''
      //   }
      //   this.fileList = []
      //   if (this.fileList.length) {
      //     this.imagesMax = true
      //   } else {
      //     this.imagesMax = false
      //   }
      // }
      if (this.formData.sign_introduce != '') {
        this.formData.sign_introduce = this.formData.sign_introduce.replace(
          /<br\/>/g,
          '\n'
        )
      }
      this.isForm = true
      this.valid()
    },
    valid() {
      console.log(this.$refs.submit.textContent == '保存')
      if (this.$refs.submit.textContent === '保存') {
        console.log(1)
        console.log(this.formData)
        if (this.formData.file_id === '') {
          delete this.formData.file_id
        }
        let info = this.formData
        for (let prop in info) {
          // console.log(prop)
          //验证是否都填写了
          if (info[prop] === '') {
            this.$message({
              message: '请完善风云人物信息',
              type: 'warning'
            })
            return
          }
        }
        this.formDataList = []

        this.formDataList.push(info)
        let arr = this.formDataList.map(item => {
          return {
            sign_name: item.sign_name,
            sign_position: item.sign_position,
            sign_introduce: item.sign_introduce,
            sign_img: item.file_id || item.sign_img
          }
        })
        console.log(arr)
        setSignApi(arr).then(res => {
          if (res.code === 0) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.getCompanyShowData({
              type: 'sign',
              succeed: res => {
                let arr = []
                console.log(res)
                this.formDataList = res.data.sign_data
                this.formDataList = res.data.sign_data
                this.formDataList = res.data.sign_data
                if (Array.isArray(this.formDataList)) {
                  this.formData = this.formDataList[0]
                }
                // if (this.formData.sign_introduce != '') {
                //   this.formData.sign_introduce = this.formData.sign_introduce.replace(
                //     /\n/g,
                //     '<br/>'
                //   )
                // }
                this.imgUrl = this.formData.sign_img
                arr.push({ url: this.formData.sign_img })
                this.fileList = arr
                if (this.fileList.length > 0) {
                  this.imagesMax = true
                } else {
                  this.imagesMax = false
                }
              }
            })

            // this.isForm = false
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      }
    },
    //修改
    changeHandler(index) {
      //修改
      this.fileList = []
      this.isChange = true
      this.isForm = true
      this.formData = this.formDataList[index]
      console.log(this.formData)
      this.fileList.push({ url: this.formData.sign_img })
      console.log(this.fileList.length)
      if (this.fileList.length > 0) {
        this.imagesMax = true
      } else {
        this.imagesMax = false
      }
    },
    //删除
    deleteHandler(index) {
      this.$confirm('是否确定删除此图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.formDataList.splice(index, 1)
          let arr = this.formDataList.map(item => {
            return {
              sign_name: item.sign_name,
              sign_position: item.sign_position,
              sign_introduce: item.sign_introduce,
              sign_img: item.file_id || item.sign_img
            }
          })
          setSignApi(arr).then(res => {
            if (res.code === 0) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              this.isForm = false
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
          // this.$message({
          //   type: 'success',
          //   message: '删除成功'
          // })
        })
        .catch(e => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCompanyShowData({
      type: 'sign',
      succeed: res => {
        console.log(res)
        let arr = []
        this.loading = false
        if (res.data.sign_data.length != 0) {
          this.formDataList = res.data.sign_data
          if (Array.isArray(this.formDataList)) {
            this.formData = this.formDataList[0]
          }
          // if (this.formData.sign_introduce != '') {
          //   this.formData.sign_introduce = this.formData.sign_introduce.replace(
          //     /\n/g,
          //     '<br/>'
          //   )
          // }
          this.imgUrl = this.formData.sign_img
          arr.push({ url: this.formData.sign_img })
          this.fileList = arr
          if (this.fileList.length > 0) {
            this.imagesMax = true
          } else {
            this.imagesMax = false
          }
        }
      }
    })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style  scoped lang="scss">
/* //@import url(); 引入公共css类 */
/* 风云人物介绍 */
.introduce /deep/ p {
  margin: 0;
  padding: 0;
  line-height: 20px;
}
.disable /deep/ .el-upload--picture-card {
  display: none;
}
.img-wrap {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  // width: 100%;
  height: 150px;
}
.img-box {
  width: 200px;
  text-align: center;
}
.img-wrap img {
  width: 150px;
  height: 150px;
  // border-radius: 50%;
}
.img-big {
  width: 100%;
}
// 职位与介绍
.post-info {
  color: #414a60;
  width: 200px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  word-wrap: break-word;
}
// 功能操作
.handle {
  width: 200px;
  text-align: right;
}
.handle span {
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  color: $jd_fontColor;
}
// .handle-icon .handle span:nth-last-child(1) {
//   border-bottom: none;
// }
.area /deep/ .el-textarea__inner {
  width: 90%;
  min-height: 100px;
  height: 100px;
  font-family: auto;
}
div /deep/ .el-form-item__label::before {
  content: '*';
  /* position: absolute; */
  padding-right: 6px;
  color: $main5_color;
}
</style>